import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"


const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <h2>About</h2>
  </Layout>
)

export default AboutPage
